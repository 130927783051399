<template>
  <div>
    <el-row>
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <header class="page-header">
          <el-row :gutter="20" align="middle" type="flex">
            <el-col :span="12">
              <router-link to="/page-list" class="mr20">
                <el-button type="default"
                  ><i class="el-icon-back"></i
                ></el-button>
              </router-link>
              <h2 class="inline-block">{{pageForm.name}} хуудасны дэлгэрэнгүй</h2>
            </el-col>
          </el-row>
        </header>
        <!-- form error template -->
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="pageForm" ref="pageForm" v-loading="loading">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <h1 style="font-family: Monospace, Monaco; color: black">{{pageForm.name}}</h1>
                </div>
                <page-items :data="pageForm.items" @update:data="dataUpdate" :updateFunction="dataUpdate"></page-items>
              </div>
            </el-col>
            <div class="panel-item">
              <el-col :span="8">
                <el-row :gutter="20">
                  <el-button :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                    style="width: 100%; margin-top: 20px" type="primary" @click="addBanner">Баннер нэмэх</el-button>
                </el-row>
                <el-row :gutter="20">
                  <el-button :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                    style="width: 100%; margin-top: 20px" type="warning" @click="addSuperBlock">Блок нэмэх</el-button>
                </el-row>
                <el-row :gutter="20">
                  <el-button :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                    style="width: 100%; margin-top: 20px" type="danger" @click="addProductList">Бүтээгдэхүүний жагсаалт
                    нэмэх</el-button>
                </el-row>
                <el-form>
                  <div class="panel" style="margin-top: 30px">
                    <div class="panel-item">
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <h4>Төлөв</h4>
                          <el-switch v-model="pageForm.is_active"
                            :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                            active-text="Идэвхтэй" inactive-text="Идэвхгүй">
                          </el-switch>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12" style="margin-top: 20px">
                          <h4>Menu харагдах хэсэг</h4>
                          <el-switch :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                          v-model="pageForm.is_menu_show"
                            active-text="Идэвхтэй" inactive-text="Идэвхгүй">
                          </el-switch>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12" style="margin-top: 20px">
                          <h4>Mobile-д харагдах эсэх</h4>
                          <el-switch :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                          v-model="pageForm.is_mobile"
                            active-text="Идэвхтэй" inactive-text="Идэвхгүй">
                          </el-switch>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20" style="margin-top: 20px">
                        <el-col :span="12">
                          <h4>Засварлах боломжтой эсэх</h4>
                          <el-switch :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = false"
                            v-model="pageForm.is_editable" active-text="Тийм" inactive-text="Үгүй">
                          </el-switch>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12" style="margin-top: 20px">
                          <h4>Badge харагдах эсэх</h4>
                          <el-switch
                           v-model="pageForm.is_badge_show"
                          :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                            active-text="Идэвхтэй" inactive-text="Идэвхгүй">
                          </el-switch>
                        </el-col>
                      </el-row>
                      <div v-if="pageForm.is_badge_show === true">
                      <el-row :gutter="20">
                        <el-col :span="12" style="margin-top: 20px">
                          <h4>Badge текст</h4>
                          <el-input :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                           type="text" size="mini" placeholder="Badge text" v-model="pageForm.badge.badge_text"></el-input>
                        </el-col>
                        <el-col :span="12" class="mt70" v-if="pageForm && pageForm.badge && pageForm.badge.badge_text !== ''">
                          <div :style="textStyles" class="colorshu">
                          {{pageForm.badge.badge_text}}
                          </div>
                        </el-col>
                        </el-row>
                        <el-row :gutter="20">
                        <el-row>
                          <el-col :span="12" style="margin-top: 20px">
                            <h4>Badge өнгө</h4>
                            <div class="block">
                            <el-color-picker :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true" v-model="pageForm.badge.badge_color"></el-color-picker>
                            </div>
                          </el-col>
                          <el-col :span="12" style="margin-top: 20px">
                            <h4>Badge текст өнгө</h4>
                            <el-color-picker :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true" v-model="pageForm.badge.badge_text_color"></el-color-picker>
                          </el-col>
                        </el-row>
                      </el-row>
                      </div>
                      <el-row :gutter="20" style="margin-top: 20px">
                        <el-col :span="12">
                          <h4>Эрэмбэ</h4>
                          <el-input :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                            type="text" size="mini" v-model="pageForm.menu_sort"></el-input>
                        </el-col>
                        <el-col :span="12">
                          <h4>Slug</h4>
                          <el-input :disabled="true"
                            type="text" size="mini" v-model="pageForm.slug"></el-input>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20" style="margin-top: 20px">
                        <el-col :span="12">
                          <h4>Эхлэх өдөр:</h4>
                          <el-input :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                            type="text" size="mini" v-model="pageForm.start_date"></el-input>
                        </el-col>
                        <el-col :span="12">
                          <h4>Хугацаа дуусах огноо:</h4>
                          <el-input :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                            type="text" size="mini" v-model="pageForm.expired_date"></el-input>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </el-form>
              </el-col>
            </div>
          </el-row>
        </el-form>
        <div class="text-right">
          <el-button style="margin-top: 20px" type="defult" @click="goBack">Буцах</el-button>
          <el-button type="success"
            @click="onPressUpdatePage">Хадгалах</el-button>
        </div>
      </el-col>
    </el-row>
    <el-dialog :title="item.type==='banner' ? 'Баннер нэмэх' : 'Супер блок'" :visible.sync="dialogVisible" width="30%">
      <el-form label-position="right" label-width="100px" :model="item">
        <el-form-item v-if="item.type === 'banner'" label="Нэр">
          <el-input placeholder="Нэрээ оруулна уу..." v-model="item.name"></el-input>
        </el-form-item>
        <el-form-item v-if="item.type === 'banner'" label="Data">
          <el-select value-key="id" remote :remote-method="getBanner" v-model="item.data_id" filterable placeholder="сонгох"
            class="block" @change="selectedBanner(banners)">
            <el-option
            v-for="(banner, index) in banners"
            :key="index"
            :label="banner.title"
            :value="banner.id">
            <span style="float: left">{{ banner.title }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ banner.banner_code }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="item.type === 'superBlock'" label="Нэр">
          <el-input mini placeholder="Нэрээ оруулна уу..." v-model="item.name"></el-input>
        </el-form-item>
        <el-form-item v-if="item.type === 'superBlock'" label="data">
          <el-select value-key="super_block_id" remote :remote-method="getBlocks" filterable v-model="item.data" placeholder="сонгох"
            class="block">
            <el-option
            v-for="(block, index1) in blockData"
            :key="index1"
            :label="block.name_mon"
            :value="block">
            <span style="float: left">{{ block.name_mon }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ block.super_block_code }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="item.type === 'productList'" label="Нэр">
          <el-select v-model="name_mon" placeholder="сонгох" class="block">
            <el-option>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="item.type === 'productList'" label="type">
          <el-input size="mini" v-model="item.type"></el-input>
        </el-form-item>
        <el-form-item v-if="item.type === 'productList'" label="redirect_url">
          <el-input size="mini" v-model="item.redirect_url"></el-input>
        </el-form-item>
        <el-form-item v-if="item.type === 'productList'" label="Төлөв">
          <el-switch v-model="item.is_active" active-text="Идвэхтэй" inactive-text="Идвэхгүй">
          </el-switch>
        </el-form-item>
      </el-form>
      <span v-if="item.type === 'banner'" slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addBannerItem">Нэмэх</el-button>
      </span>
      <span v-if="item.type === 'superBlock'" slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="warning" @click="addBlockItem">Нэмэх</el-button>
      </span>
      <span v-if="item.type === 'productList'" slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="danger" @click="addProductListItem">Нэмэх</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pageItems from '../../pages/page/components/pageItems.vue'
import services from '../../../helpers/services'
export default {
  name: 'detailPage',
  components: {
    pageItems
  },
  watch: {
    'pageForm.items': function (val) {
      this.pageForm.items.forEach((item, index) => {
        this.pageForm.items[index].sort = index
      })
    }
  },
  created () {
    if (this.$route.params.slug) {
      this.getOnePage(this.$route.params.slug)
    }
    this.getBanner()
    this.getBlocks()
    // this.getProducts()
  },
  computed: {
    textStyles () {
      return {
        'background-color': this.pageForm.badge.badge_color,
        color: this.pageForm.badge.badge_text_color
      }
    }
  },
  data () {
    return {
      dialogVisible: false,
      item: {},
      pageForm: {
        is_menu_show: false,
        name: '',
        menu_sort: '',
        is_active: '',
        data_id: '',
        slug: '',
        redirect_url: '',
        items: [{
          name: '',
          sort: '',
          type: ''
        }]
      },
      errorArray: [],
      banners: [],
      blockData: [],
      productList: [],
      loading: false
    }
  },
  methods: {
    dataUpdate: function (data) {
      this.pageForm.items = data
    },
    selectedBanner (banners) {
      this.item.data = banners.find(x => x.id === this.item.data_id)
    },
    getBanner (query) {
      this.loading = true
      services.getBanner(1000, 0, query).then(response => {
        if (response.status === 'success') {
          this.totalCount = response.total
          this.banners = response.data
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    onPressUpdatePage () {
      const query = this.pageForm.slug
      services.getPageUpdate(query, this.pageForm).then((response) => {
        this.loading = false
        this.alertReporter(
          response.status === 'success' ? 'Амжилттай' : 'Уучлаарай',
          response.message,
          response.status
        )
        if (response.status === 'success') {
          this.loading = false
          this.$router.push({
            name: 'pageList'
          })
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', response.message, 'error')
        } else {
          return false
        }
      })
    },
    addBannerItem () {
      this.pageForm.items.push(this.item)
      this.dialogVisible = false
    },
    addBlockItem () {
      const tempData = JSON.parse(JSON.stringify(this.item))
      // tempData.id = this.item.id
      tempData.id = tempData.data.super_block_id
      tempData.data_id = tempData.id
      delete tempData.id
      this.pageForm.items.push(tempData)
      this.dialogVisible = false
    },
    addProductListItem () {
      this.pageForm.items.push(this.item)
      this.dialogVisible = false
    },
    addBanner () {
      this.item = {
        type: 'banner'
      }
      this.dialogVisible = true
    },
    addSuperBlock () {
      this.item = {
        type: 'superBlock'
      }
      this.dialogVisible = true
    },
    addProductList () {
      this.item = {
        type: 'productList'
      }
      this.dialogVisible = true
    },
    goBack () {
      this.$router.go(-1)
    },
    getBlocks () {
      const query = '?size=1000&from=0&'
      this.isLoading = true
      services.getBlocks(query).then(response => {
        this.blockData = response.datas
        this.totalCount = response.total
        this.isLoading = false
      })
    },
    // getProducts () {
    //   const body = {
    //     // supplier: this.selectedSuppliers,
    //     // brand: this.selectedBrands
    //   }
    //   const query = ''
    //   this.isLoading = true
    //   services.getAllProducts(body, query).then(response => {
    //     if (response.datas && response.datas.length > 0) {
    //       this.productList = response.datas
    //       this.totalCount = response.total
    //     } else if (response.status === 'error') {
    //       this.alertReporter('Алдаа', response.message, 'error')
    //       this.loading = false
    //     }
    //   })
    // },
    getOnePage (data) {
      this.loading = true
      services.getOnePage(data).then((response) => {
        if (response.status === 'success') {
          this.pageForm = response.data
          this.loading = false
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', response.message, 'error')
          this.loading = false
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}

</script>
<style scoped>
.colorshu {
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}
</style>
